import { render, staticRenderFns } from "./CamiloCode.vue?vue&type=template&id=623dea82&scoped=true&"
import script from "./CamiloCode.vue?vue&type=script&lang=ts&"
export * from "./CamiloCode.vue?vue&type=script&lang=ts&"
import style0 from "./CamiloCode.vue?vue&type=style&index=0&id=623dea82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "623dea82",
  null
  
)

export default component.exports